<template>
  <div class="calendar-page">
    <template v-if="isInitialFetched">
      <div class="flex flex-wrap mb-4">
          <div class="col-options--button refresh-icon pt-5 " title="Refresh data" >
              <vs-button color="dark" class="mr-2" @click="reloadEvents">Reload</vs-button>
          </div>
        <div class="flex mb-4 align-middle pr-4">
          <div style="width: 250px">
            <div>
              <label class="vs-input--label">Kategorie Filtern</label>
              <v-select
                  v-model="selectedCategory"
                  :options="categories"
                  autowidth
                  label="name"
                  :searchable="false"
                  placeholder="Kategorie filtern"
                  @input="changeCategory"
              >
              </v-select>
            </div>
          </div>
        </div>

        <div class="flex mb-4 items-center">
          <div>
            <label class="vs-input--label">Benutzer Filtern</label>
            <v-select
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                autowidth
                label="id"
                :searchable="false"
                placeholder="Zuweisungen filtern"
                @input="reloadEvents"
            >
              <template #selected-option="{ detail }">
                            <span v-if="detail">
                                {{ detail.first_name }}   {{ detail.last_name }}
                            </span>
              </template>
              <template #option="{ detail }">
                            <span v-if="detail">
                                {{ detail.first_name }}   {{ detail.last_name }}
                            </span>
              </template>
            </v-select>
          </div>
          <vs-checkbox v-model="showCreatedEvents" class="ml-4 mt-5" @change="changeShowCreated">Von mir
            erstellte Ereignisse anzeigen
          </vs-checkbox>

        </div>
      </div>
    </template>
    <FullCalendar
        :style="{visibility: (isInitialFetched?'visible':'hidden')}"
        class="calendar"
        ref="fullCalendar"
        defaultView="dayGridMonth"
        :header="{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        }"
        :timeZone="timeZone"
        :locale="locale"
        :plugins="calendarPlugins"
        :weekends="calendarWeekends"
        :events="calendarEvents"
        :editable="true"
        @dateClick="onDateClick"
        @eventDrop="onResize"
        @eventResize="onResize"
        @eventClick="onEventClick"
        @eventRender="eventRender"
    />

    <template v-if="isInitialFetched">
      <event-popup :active.sync="eventPopupActive" :event="currentEvent" @saved="reloadEvents"
                   @close="onEventPopupClosed"></event-popup>
    </template>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import deLocale from '@fullcalendar/core/locales/de';
import EventPopup from "./components/EventPopup";
import moment from 'moment';
import {mapGetters} from "vuex";
import ApiService from "../../../api";
import vSelect from 'vue-select'

export default {
  components: {
    EventPopup,
    FullCalendar,
    'v-select': vSelect
  },
  data: function () {
    return {
      timeZone: 'Europe/Berlin',
      locale: deLocale,
      calendarPlugins: [ // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      calendarEvents: [],
      eventPopupActive: false,
      currentEvent: null,
      categories: [],
      selectedCategory: null,
      initialEvents: [],
      initialSpecialEvents: [],
      showCreatedEvents: false,
      backgroundEvents: [],
      selectedUsers: [
        {id: 0, detail: {first_name: 'Ohne Zuweisungen', last_name: ''}}
      ],
      users: [
        {id: 0, detail: {first_name: 'Ohne Zuweisungen', last_name: ''}}
      ],
      isInitialFetched: false,
    }
  },
  computed: {
    ...mapGetters(['userId', 'user']),
    eventFilter() {

      let filter = '',
          counter = 0;

      for (const user of this.selectedUsers) {

        if (user.id === 0) {
          if (counter === 0) {
            filter += `?filter[${counter}][field]=users`;
          } else {
            filter += `&filter[${counter}][field]=users`;
          }

          filter += `&filter[${counter}][expression]=doesntHave` +
              `&filter[${counter}][operator]=OR`;
        } else {
          if (counter === 0) {
            filter += `?filter[${counter}][field]=users.id`;
          } else {
            filter += `&filter[${counter}][field]=users.id`;
          }

          filter += `&filter[${counter}][value]=${user.id}` +
              `&filter[${counter}][operator]=OR`;
        }

        counter++;
      }

      if (this.showCreatedEvents) {
        if (counter === 0) {
          filter += `?filter[${counter}][field]=creator_user_id`;
        } else {
          filter += `&filter[${counter}][field]=creator_user_id`;
        }

        filter += `&filter[${counter}][value]=${this.userId}` +
            `&filter[${counter}][operator]=OR`;
      }

      return filter;
    }
  },
  created() {
    this.$vs.loading();
    this.isInitialFetched = false;

    this.selectedUsers.push(this.user);

    Promise.all([
        this.fetchCategoriesPromise(),
        this.fetchUsersPromise(),
        this.fetchEventsPromise(),
        this.fetchHolidaysPromise(),
        this.fetchSpecialEventsPromise()
    ]).then(response => {
      this.categories = response[0].data.result;

      this.users = [...this.users, ...response[1].data.result];

      this.calendarEvents = [];
      this.initialEvents = response[2].data.result;
      this.initialSpecialEvents = response[4].data.result;

      this.backgroundEvents = response[3].data.result.filter(holiday => {
        if (holiday.regions) {
          return holiday.regions.bw;
        }
        return false;
      });

      this.changeCategory();

      this.isInitialFetched = true;
      this.$vs.loading.close();

      this.openEventByRouteQuery();

    }).catch((error) => {
      this.$vs.loading.close();
      console.error(error.message);
      return this.$vs.notify({
        title: 'Fehler',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    });
  },
  methods: {
    eventRender({el, event}){
      if(event.rendering === 'background' && event.groupId === 'holidays') {
        let bgEvent = document.createElement('span');

        bgEvent.style.color = '#000000';
        bgEvent.style.fontSize = '12px';
        bgEvent.style.paddingLeft = '5px';

        bgEvent.innerHTML = event.title;

        el.append(bgEvent)
      }
    },

    onResize(data) {
      let requestData = null;

      if (data.event.end) {
        if (data.event.allDay) {
          requestData = {
            start_at: moment(data.event.start).format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment(data.event.end).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
          };
        } else {
          requestData = {
            start_at: moment(data.event.start).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment(data.event.end).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss')
          };
        }
      } else {
        requestData = {
          start_at: moment(data.event.start).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
          end_at: null
        };
      }

      this.updateEvent(data.event.id, requestData);
    },
    openEventByRouteQuery() {
      if (!this.$route.query.eId) {
        return;
      }

      const eId = parseInt(this.$route.query.eId);
      const event = this.calendarEvents.find(item => item.id == eId);

      if (!event) {
        this.$router.push({name: 'calendar', query: {}});
        return;
      }

      this.currentEvent = event.rawData;
      this.$refs.fullCalendar.getApi().gotoDate(event.date);
      this.eventPopupActive = true;
    },
    onEventClick(data) {
      this.$router.push({name: 'calendar', query: {eId: data.event.extendedProps.rawData.id}});
      this.currentEvent = data.event.extendedProps.rawData;
      this.eventPopupActive = true;
    },
    onEventPopupClosed() {
      if (this.$route.query.eId) {
        this.$router.push({name: 'calendar', query: {}});
      }
    },
    onDateClick(arg) {
      this.currentEvent = this.createEmptyEventData(arg.date, arg.allDay);
      this.eventPopupActive = true;
    },
    fetchCategoriesPromise() {
      let filter = '?filter[0][field]=usable_on&filter[0][value]=calendar';

      return ApiService.get('categories' + filter);
    },

    fetchHolidaysPromise() {
      let filter = '?filter[0][field]=usable_on&filter[0][value]=calendar';

      return ApiService.get('calendar-holidays');
    },


    fetchUsersPromise() {
      return ApiService.get('users');
    },
    changeShowCreated() {
      this.reloadEvents();
    },
    changeCategory() {
      this.calendarEvents = [];
      let filteredEvents = [].concat(this.initialEvents, this.initialSpecialEvents);

      if (this.selectedCategory) {
        filteredEvents = filteredEvents.filter((event) => {
          if (!event.category) {
            return false;
          }
          return event.category.id === this.selectedCategory.id;
        });
      }

      for (let event of filteredEvents) {

        if (event.is_whole_day) {
          this.calendarEvents.push({
            id: event.id,
            title: event.title + this.getUserShorts(event.users),
            date: event.start_at,
            end: event.end_at !== null ? moment(event.end_at).add(1, 'day').format('YYYY-MM-DD') : null,
            allDay: event.is_whole_day,
            rawData: event,
            backgroundColor: event.color ? `#${event.color}` : null,
            borderColor: event.color ? `#${event.color}` : null,
            startEditable: !event.special_event,
            durationEditable: !event.special_event,
          });
        } else {
          this.calendarEvents.push({
            id: event.id,
            title: event.title + this.getUserShorts(event.users),
            date: event.start_at,
            end: event.end_at,
            allDay: event.is_whole_day,
            rawData: event,
            backgroundColor: event.color ? `#${event.color}` : null,
            borderColor: event.color ? `#${event.color}` : null,
            startEditable: !event.special_event,
            durationEditable: !event.special_event,
          });
        }

      }

      for (let backgroundEvent of this.backgroundEvents) {
        this.calendarEvents.push({
          groupId: 'holidays',
          start: backgroundEvent.date,
          display: 'background',
          allDay: true,
          title: backgroundEvent.name,
          rendering: "background",
        });
      }
    },
    getUserShorts(users) {
      const shorts = users.map(user => {
        return user.detail.first_name.charAt(0) +
            user.detail.first_name.charAt(1) +
            user.detail.last_name.charAt(0) +
            user.detail.last_name.charAt(1)
      }, []);

      if (shorts.length < 1) {
        return '';
      }

      return ` (${shorts.join(', ')})`;
    },
    fetchEventsPromise() {
      return ApiService.get('calendar-events' + this.eventFilter);
    },
    fetchSpecialEventsPromise() {
      return ApiService.get('calendar-special-events' + this.eventFilter);
    },
    reloadEvents() {
      Promise.all([
        this.fetchEventsPromise(),
        this.fetchSpecialEventsPromise()
      ]).then((responses) => {
        this.$vs.loading.close();

        this.calendarEvents = [];

        this.initialEvents = responses[0].data.result;
        this.initialSpecialEvents = responses[1].data.result;

        this.changeCategory();
      }).catch((error) => {
        this.$vs.loading.close();
        return this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },

    updateEvent(id, data) {
      this.$vs.loading();

      ApiService.put('calendar-events/' + id, data).then((response) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: 'Das Ereignis wurde bearbeitet.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        });
        this.reloadEvents();
      }).catch((error) => {
        this.$vs.loading.close();
        return this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },


    createEmptyEventData(start, whole_day) {
      if (whole_day) {
        return {
          title: null,
          description: null,
          content: null,
          url: null,
          start_at: moment(start).set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD HH:mm:ss'),
          day_at: start,
          end_at: null,
          is_whole_day: whole_day,
          reminder_count: 0,
          reminder_interval: 0,
          reminder_unit: 'd',
          color: '1abc9c',
          user_id: this.userId,
          users: [{id: this.userId}],
          creator_user_id: this.userId,
          recurring_event: false,
          recurring_config: null
        }
      }

      return {
        title: null,
        description: null,
        content: null,
        url: null,
        start_at: moment(start).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
        day_at: start,
        end_at: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        is_whole_day: whole_day,
        reminder_count: 0,
        reminder_interval: 0,
        reminder_unit: 'd',
        color: '1abc9c',
        user_id: this.userId,
        users: [{id: this.userId}],
        creator_user_id: this.userId,
        recurring_event: false,
        recurring_config: null
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

.fc-view-container {
  background: #fff;
}

.fc-event {
  .fc-content {
    padding: 2px 4px;
  }
}
</style>
