var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-page"},[(_vm.isInitialFetched)?[_c('div',{staticClass:"flex flex-wrap mb-4"},[_c('div',{staticClass:"col-options--button refresh-icon pt-5 ",attrs:{"title":"Refresh data"}},[_c('vs-button',{staticClass:"mr-2",attrs:{"color":"dark"},on:{"click":_vm.reloadEvents}},[_vm._v("Reload")])],1),_c('div',{staticClass:"flex mb-4 align-middle pr-4"},[_c('div',{staticStyle:{"width":"250px"}},[_c('div',[_c('label',{staticClass:"vs-input--label"},[_vm._v("Kategorie Filtern")]),_c('v-select',{attrs:{"options":_vm.categories,"autowidth":"","label":"name","searchable":false,"placeholder":"Kategorie filtern"},on:{"input":_vm.changeCategory},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1)])]),_c('div',{staticClass:"flex mb-4 items-center"},[_c('div',[_c('label',{staticClass:"vs-input--label"},[_vm._v("Benutzer Filtern")]),_c('v-select',{attrs:{"options":_vm.users,"multiple":true,"autowidth":"","label":"id","searchable":false,"placeholder":"Zuweisungen filtern"},on:{"input":_vm.reloadEvents},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var detail = ref.detail;
return [(detail)?_c('span',[_vm._v(" "+_vm._s(detail.first_name)+" "+_vm._s(detail.last_name)+" ")]):_vm._e()]}},{key:"option",fn:function(ref){
var detail = ref.detail;
return [(detail)?_c('span',[_vm._v(" "+_vm._s(detail.first_name)+" "+_vm._s(detail.last_name)+" ")]):_vm._e()]}}],null,false,1198744045),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),_c('vs-checkbox',{staticClass:"ml-4 mt-5",on:{"change":_vm.changeShowCreated},model:{value:(_vm.showCreatedEvents),callback:function ($$v) {_vm.showCreatedEvents=$$v},expression:"showCreatedEvents"}},[_vm._v("Von mir erstellte Ereignisse anzeigen ")])],1)])]:_vm._e(),_c('FullCalendar',{ref:"fullCalendar",staticClass:"calendar",style:({visibility: (_vm.isInitialFetched?'visible':'hidden')}),attrs:{"defaultView":"dayGridMonth","header":{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },"timeZone":_vm.timeZone,"locale":_vm.locale,"plugins":_vm.calendarPlugins,"weekends":_vm.calendarWeekends,"events":_vm.calendarEvents,"editable":true},on:{"dateClick":_vm.onDateClick,"eventDrop":_vm.onResize,"eventResize":_vm.onResize,"eventClick":_vm.onEventClick,"eventRender":_vm.eventRender}}),(_vm.isInitialFetched)?[_c('event-popup',{attrs:{"active":_vm.eventPopupActive,"event":_vm.currentEvent},on:{"update:active":function($event){_vm.eventPopupActive=$event},"saved":_vm.reloadEvents,"close":_vm.onEventPopupClosed}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }